import { Config } from "../utils"
import { AxiosPromise } from 'axios';
import { isClient } from "../utils/browser"
import * as moment from 'moment'
import { errorHandler, conferenceClient } from "../utils/Client"

export type ParticipantOriginInfo = {
  id: string
  session_id: string
  name: string
  room_id: string
}

export type ConferenceRoom = {
  id: string
  title: string
  private_room: boolean
  created_at: Date
  created_by: any
  participants: ParticipantOriginInfo[]
}

export class ConferenceRoomInfo {
  id: string
  title: string
  privateRoom: boolean
  createdAt: string
  createdBy: any
  isHost: boolean
  participants: ParticipantOriginInfo[]

  constructor(json: ConferenceRoom) {
    this.id = json.id
    this.title = json.title
    this.privateRoom = json.private_room
    this.createdAt = moment(json.created_at).format("YYYY.MM.DD HH:mm")
    this.createdBy = json.created_by
    this.isHost = isClient && localStorage.getItem(Config.DEMO_USERNAME_KEY) === json?.created_by?.user_id
    this.participants = json.participants
  }
}

export type Participant = {
  id: string
  name: string
  room_id: string
  session_id: string
}

export class ParticipantInfo {
  id: string
  name: string
  roomId: string
  sessionId: string
  isMe: boolean

  constructor(json: Participant) {
    this.id = json.id
    this.name = json.name
    this.roomId = json.room_id
    this.sessionId = json.session_id
    this.isMe = isClient && localStorage.getItem(Config.DEMO_USERNAME_KEY) === json?.name
  }
}

export const createParticipantInfoList = (participants: Participant[]) => {
  const participantList = []

  for(const participant of participants) {
    participantList.push(new ParticipantInfo(participant));
  }

  return participantList;
}

export interface AddConferenceRoomRequest {
  title: string
  pin: string
}

export const addConferenceRoom = (request: AddConferenceRoomRequest): AxiosPromise => {
  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)
  
  return conferenceClient().setAccessToken(userToken).post("/rooms", request)
}

export const getConferenceRoom = (roomId: string, callback:(room: ConferenceRoomInfo) => void): void => {
  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)

  conferenceClient().setAccessToken(userToken).get(`/rooms/${roomId}`).then((response) => {
    callback(new ConferenceRoomInfo(response.data));
  }, errorHandler)
}

export const getConferenceRoomList = (callback:(roomList: ConferenceRoomInfo[]) => void): void => {
  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)

  conferenceClient().setAccessToken(userToken).get(`/rooms`).then((response) => {
    const roomList = []

    for(const room of response.data) {
      roomList.push(new ConferenceRoomInfo(room));
    }

    callback(roomList);
  }, errorHandler)
}


export const deleteConferenceRoom = (roomId: string): AxiosPromise => {
  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)

  return conferenceClient().setAccessToken(userToken).delete(`/rooms/${roomId}`)
}

export const getPaticipantsByRoom = (roomId: string, callback: (participants: ParticipantInfo[]) => void): void => {
  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)

  conferenceClient().setAccessToken(userToken).get(`/rooms/${roomId}/participants`).then((response) => {
    const participants = []

    for(const participant of response.data) {
      participants.push(new ParticipantInfo(participant));
    }

    callback(participants);
  }, errorHandler)
}

export const removeParticipant = (roomId: string, participantId): AxiosPromise => {
  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)

  return conferenceClient().setAccessToken(userToken).delete(`/rooms/${roomId}/participants/${participantId}`)
}



export const getRoom = (roomId: string, callback: (room: ConferenceRoomInfo) => void): void => {
  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)
  
  conferenceClient().setAccessToken(userToken).get(`/rooms/${roomId}`).then((response) => {

    console.log("getRoom", response.data)
    callback(new ConferenceRoomInfo(response.data));
  }, errorHandler)
}

interface AuthRoomRequest {
  id: string
  pin: string
}

export const authRoom = (authRequest: AuthRoomRequest, success:() => void, failure:() => void ): void => {
  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)

  const request = {
    pin: authRequest.pin
  }

  conferenceClient().setAccessToken(userToken).post(`/rooms/${authRequest.id}/auth`, request).then((response) => {
    console.log(response.data)
    success();
  }, err => {
    failure()
  })
}

