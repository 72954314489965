import * as React from 'react';

export default function ConferenceRoomListSkeleton () {
  return (
    <div className="room-list-group" style={{display: "flex"}}>
      {[...Array(6).keys()].map(i => (
        <div className="card room-item-group skeleton-item" key={i}>
          <div className="room-content-group">
            <h1 className="skeleton-name"></h1>
            <p className="skeleton-desc"></p>
            <div className="participants-group skeleton-participants"></div>
          </div>
        </div>)
      )}
    </div>
  )
}