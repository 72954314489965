import { navigate } from 'gatsby-link';
import * as React from 'react';
import { useState } from 'react'
import { useSelector } from 'react-redux';
import Button from '../components/button/Button'
import Switch from '../components/input/Switch';
import TextInput from '../components/input/TextInput'
import { StoreState } from '../modules';
import { Config } from '../utils';
import { isClient } from '../utils/browser';

interface Props {
  toggle(): void
  roomId: string
}

export default function EnterConferenceForm({toggle, roomId}: Props): JSX.Element {
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [isCam, setIsCam] = useState(isClient && typeof JSON.parse(localStorage.getItem(Config.DEMO_IS_CAM_KEY)) === "boolean" ? JSON.parse(localStorage.getItem(Config.DEMO_IS_CAM_KEY)) : false);
  const { conference } = useSelector((state: StoreState) => ({conference: state.conference.conference}))

  const onChangePassword = React.useCallback((e: any): void => {
    setPassword(e.target.value)
  }, [password])

  const onChangeCheck = (e: React.FormEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.checked
    setIsCam(value)
    localStorage.setItem(Config.DEMO_IS_CAM_KEY, `${value}`)
  }

  const onSuccess = () => {
    if(window.location.pathname.startsWith("/demo/conference/viewer")) {
       
    } else {
      navigate(`/demo/conference/viewer?id=${roomId}&pin=${btoa(password)}`)
    }
  }

  const onFailure = () => {
    setPasswordError("Password is not vaild")
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (password === '') {
      setPasswordError('Please enter your password')
      return
    }
    setPasswordError('')

    try {
      conference.checkPin(roomId, password).then((valid) => {      
        valid ? onSuccess() : onFailure();
      });
    } catch {
      onFailure();
    }
  }

  return (
    <form className="demo-information-modal-container" onSubmit={handleSubmit}>
      <TextInput type="password" name="password" label="Password" value={password} onChange={onChangePassword} maxLength={200} required error={passwordError} />
      <div className="form-switch-group" style={{marginBottom: "55px"}}>
        <label>비디오 켜짐</label>
        <Switch checked={isCam} onChange={onChangeCheck} />
      </div>
      <div className="button-double-group">
        <Button type="button" size="large" color="white" onClick={toggle}>Cancel</Button>
        <Button type="submit" size="large" color="red" >Submit</Button>
      </div>
    </form>
  )
}
