import * as React from 'react';
import Toast from '../components/Toast';
import ConferenceRoomItem from './ConferenceRoomItem';
import { ConferenceRoomInfo, deleteConferenceRoom } from './conferences';

interface NoRoomItemProps {
  setToggle(): void
}

function NoRoomItem({setToggle}: NoRoomItemProps) {
  return (
    <>
      <button type="button" className="card room-item-group flex-center noroom" onClick={setToggle}>
        <div className="cross"/>
      </button> 
      <div className="card room-item-group flex-center">
        <p style={{color: "#999"}}>참여 가능한 방이 없습니다.</p>
      </div>
    </> 
  )
}

interface Props {
  setToggle(): void
  roomList: ConferenceRoomInfo[]
  refreshRoomList(): void
}

export default function ConferenceRoomList({setToggle, roomList}: Props) { 
  return (
    <>
      <div className="room-list-group" style={{display: "flex"}}>
        {roomList?.length > 0 ? 
          roomList.map((room, i) => <ConferenceRoomItem key={i} room={room} />) :
          <NoRoomItem setToggle={setToggle}/>}
      </div>
    </>
  )
}