import * as React from 'react';
import { Config } from '../../utils';

interface Props {
  avatars?: string[];
  length?: number;
}

export default function AvatarGroup({avatars, length}: Props) {
  const restAvatarCount = length ? length - 4 : avatars ? avatars.length - 4 : 0
  return (
    <div className="avatar-group">
      {restAvatarCount > 0 && <span className="avatar">
        <div className="rest-avatar-count">{`${restAvatarCount}+`}</div>
      </span>}
      {length ? [...Array(length).keys()].slice(0,4).map((i) => <span key={i} className="avatar">
            <img src={Config.DEFAULT_AVATAR} alt="participant_avatar_image" />
          </span>) :
        avatars && avatars?.slice(0,4).map((avatar, i) => {
          return (<span key={avatar} className="avatar">
            <img src={avatar} />
          </span>)}
      )}
    </div>
  );
}
