import * as React from 'react';
import { useState } from 'react'
import Button from '../components/button/Button'
import TextInput from '../components/input/TextInput'
import { Config } from '../utils';
import { AddConferenceRoomRequest } from './conferences';

interface Props {
  toggle(): void
  addRoom(request: AddConferenceRoomRequest): void
}

export default function CreateRoomForm({toggle, addRoom}: Props): JSX.Element {
  const [title, setTitle] = useState('')
  const [password, setPassword] = useState('')
  const [titleError, setTitleError] = useState('')
  const [passwordError, setPasswordError] = useState('')


  const onChangeInput = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget;
    switch (name) {
      case 'title':
        setTitle(value)
        break
      case 'password':
        setPassword(value)
        break
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (title === '') {
      setTitleError('Please enter your title')
      return
    }
    setTitleError('')

    if (password === '') {
      setPasswordError('Please enter your password')
      return
    }
    setPasswordError('')

    const request = {
      title,
      pin: password,
    }

    localStorage.removeItem(Config.DEMO_EMAIL_KEY);
    addRoom(request)
    toggle();
  }

  return (
    <form className="demo-information-modal-container demo-create-room-container" onSubmit={handleSubmit}>
      <TextInput type="text" name="title" label="Title" value={title} onChange={onChangeInput} maxLength={200} required error={titleError} />
      <TextInput type="password" name="password" label="password" value={password} onChange={onChangeInput} maxLength={200} required error={passwordError} />
      <div className="button-double-group">
        <Button type="button" size="large" color="white" onClick={toggle}>Cancel</Button>
        <Button type="submit" size="large" color="red">Submit</Button>
      </div>
    </form>
  )
}
