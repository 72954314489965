import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as conferenceActions } from '../../../modules/conference'
import Footer from '../../../components/Footer';
import Title from '../../../components/title/Title';
import Modal from '../../../components/modal/Modal';
import LayoutProvider from '../../../components/layout/LayoutProvider';
import { Config } from '../../../utils';
import InformationSample from '../../../demo/InfomationSample';
import { StoreState } from '../../../modules';
import { isClient } from '../../../utils/browser';
import ConferenceRoomList from '../../../conference/ConferenceRoomList';
import CreateRoomForm from '../../../conference/CreateRoomFrom';
import { ConferenceRoomInfo, AddConferenceRoomRequest, getConferenceRoomList, addConferenceRoom } from '../../../conference/conferences';
import { genUserName } from '../../../utils/Format';
import { navigate } from 'gatsby-link';
import ConferenceRoomListSkeleton from '../../../conference/ConferenceRoomListSkeleton';

interface Props {
  actions: typeof conferenceActions
  streamState: string
  title: string
  location: any
}

interface State {
  stage: number
  username: string
  visibleForm: boolean
  roomList: ConferenceRoomInfo[] | null
  visibleInfo: boolean
}

class DemoContainer extends React.Component<Props, State> {
  state: State = {
    stage: 0,
    username: '',
    visibleInfo: !Boolean(isClient && localStorage.getItem(Config.DEMO_EMAIL_KEY)),
    visibleForm: false,
    roomList: null,
  }

  sdk: any;
  conference: any;

  initDemo = async () => {
    const { FlipFlop } = (await import('flipflop-sdk-javascript/dist/flipflop'))
    const appKey = Config.DEMO_APP_KEY
    const appSecret = Config.DEMO_APP_SECRET
    let username = isClient && localStorage.getItem(Config.DEMO_USERNAME_KEY)
    const profilePhoto = ""
    

    if(!username) {
      username = genUserName()
      localStorage.setItem(Config.DEMO_USERNAME_KEY, username)
    }

    console.log(`userId: ${username}, userName: ${username}`);
    
    FlipFlop.initialize(appKey, appSecret);
    this.sdk = await FlipFlop.authentication(username, username, profilePhoto);

    this.conference = this.sdk.getConference();
    this.props.actions.setConference(this.conference);
  }
  
  toggleInfo = () => {
    this.setState(prevState => ({visibleInfo: !prevState.visibleInfo}))
  }

  toggleForm = () => [
    this.setState(prevState => ({visibleForm: !prevState.visibleForm}))
  ]

  setRoomList = (rooms: any[]) => {
    const roomList = []

    for(const room of rooms) {
      roomList.push(new ConferenceRoomInfo(room));
    }

    this.setState({ roomList })
  }

  initConferenceRoomList = () => {
    this.conference?.getRooms().then(this.setRoomList);
  }

  addRoom = (request: AddConferenceRoomRequest) => {
    this.conference?.createRoom(request.title, request.pin).then(() => {
      this.initConferenceRoomList()
    }).catch((err) => {
      console.log(err)
      alert('Failed to add room')
    })
  }

  componentDidMount () {
    this.initDemo().then(() => {
        this.initConferenceRoomList()
      }
    )
  }

  public render() {
    const {roomList} = this.state;

    return (
      <LayoutProvider location={this.props.location} className="demo-container" render={() => 
        <>
          <div className="content-container" style={{marginBottom: "0", minHeight: "calc(100vh - 150px)"}}>
            <div className="demo-content row" >
              <Title size="xlarge" color="black" className="montserrat-font" style={{marginBottom: "40px"}}>Video conference</Title>
              {roomList ? <ConferenceRoomList setToggle={this.toggleForm} roomList={roomList} refreshRoomList={this.initConferenceRoomList}/> : <ConferenceRoomListSkeleton />}
            </div>
            <div className="plus-button-group">
              <button className="circle plus-button flex-center" onClick={this.toggleForm}>
                <div className="cross" />
              </button>
            </div>
          </div>
          <Footer />
          <Modal visible={this.state.visibleInfo} setVisible={this.toggleInfo} onClose={() => {navigate("/demo")}} render={<InformationSample toggle={this.toggleInfo}/>} title={"Infomation"} disabled/>
          <Modal title="Create room" visible={this.state.visibleForm} setVisible={this.toggleForm} render={<CreateRoomForm addRoom={this.addRoom} toggle={this.toggleForm} />}/>
        </>
      } />
    )
  }
}

export default connect(
	({} : StoreState) => ({
	}),
	(dispatch) => ({
    actions: bindActionCreators(conferenceActions, dispatch),
  })
)(DemoContainer)