import * as React from 'react'
import AvatarGroup from '../components/avatar/AvatarGroup'
import Button from '../components/button/Button'
import Modal from '../components/modal/Modal'
import Title from '../components/title/Title'
import EnterConferenceForm from './EnterConferenceForm'
import { ConferenceRoomInfo } from './conferences'

enum ModalStateCode {
  Enter = 1,
}

interface Props {
  room: ConferenceRoomInfo
}

export default function ConferenceRoomItem ({room}: Props) {
  const [visibleModal, setVisibleModal] = React.useState(0);
  
  const toggleModalGenerator = React.useCallback((modalCode: ModalStateCode) => (): void => {
    if (visibleModal === modalCode) {
      setVisibleModal(0)
    } else {
      setVisibleModal(modalCode)
    }
  }, [visibleModal, setVisibleModal])

  const toggleEnter = toggleModalGenerator(ModalStateCode.Enter)

  return (
    <div className="card room-item-group">
      <div className="room-content-group">
        <Title size="large" color="black" className="montserrat-font">{`Room`}</Title>
        <Title size="large" color="black" className="montserrat-font">{room.title}</Title>
        <p>{room.createdAt}</p>
        <div className="participants-group">
          {room.participants.length > 0 && <AvatarGroup length={room.participants.length} />}
          <span style={{lineHeight: 1}}>{`참여인원 ${room.participants.length}명`}</span>
        </div>
      </div>
      <Button color="orange" size="ssmall" className="montserrat-font join-button" onClick={toggleEnter}>JOIN</Button>
      <Modal title="Password" visible={visibleModal === ModalStateCode.Enter} setVisible={toggleEnter} render={<EnterConferenceForm toggle={toggleEnter} roomId={room.id}/>} />
    </div>
  )
}