import * as React from 'react'
import './Switch.scss'


interface Props {
  id?: string
  value?: string
  checked : boolean
  onChange(e: React.FormEvent<HTMLInputElement>): void
}

export default function Switch({ id, value, checked, onChange }: Props): JSX.Element {
  return (
    <div className="switch-group">
      <label className="switch">
        <input type="checkbox" id={id} value={value} checked={checked} onChange={onChange} />
        <span className="slider round" />
      </label>
    </div>
  )
}